<template>
  <div class="home">
    <NavBar />
    <div class="first-section">
      <div class="top">
        <div class="left">
          <h1>Unlock Your Dream Home Today!</h1>
          <p>
            Discover Your Perfect Home: Buy or Rent with Ease on Our Exceptional
            Platform
          </p>
        </div>
        <div class="right">
          <v-carousel
            height="400"
            hide-delimiter-background
            v-if="slides.length > 0"
            show-arrows="hover"
            cycle
          >
            <v-carousel-item
              v-for="(slide, i) in slides"
              :key="i"
              :src="$upload(slide.image)"
            >
            </v-carousel-item>
          </v-carousel>
        </div>
      </div>
      <div class="bottom">
        <form action="">
          <div class="left">
            <div class="form-group group-4" v-if="locations.length">
              <div class="input-group">
                <label for=""> Location </label>
                <a-select
                  v-model:value="searchData.location"
                  show-search
                  placeholder="Any"
                  :size="'large'"
                  style="width: 100%; font-size: 16px"
                  :options="locationOptions"
                  :filter-option="filterOption"
                ></a-select>
              </div>
              <!-- pROPERTY tYPE -->
              <div class="input-group">
                <label for=""> Type </label>
                <a-select
                  v-model:value="searchData.category"
                  show-search
                  placeholder="Any"
                  :size="'large'"
                  style="width: 100%; font-size: 16px"
                  :options="categoryOptions"
                  :filter-option="filterOption"
                ></a-select>
              </div>
              <!-- Status -->
              <div class="input-group">
                <label for=""> Status </label>
                <a-select
                  v-model:value="searchData.type"
                  show-search
                  placeholder="Any"
                  :size="'large'"
                  style="width: 100%; font-size: 16px"
                  :options="typeOptions"
                  :filter-option="filterOption"
                ></a-select>
              </div>
              <!-- Condition -->
              <div class="input-group">
                <label for=""> Condition </label>
                <a-select
                  v-model:value="searchData.status"
                  show-search
                  placeholder="Any"
                  :size="'large'"
                  style="width: 100%; font-size: 16px"
                  :options="statusOptions"
                  :filter-option="filterOption"
                ></a-select>
              </div>
              <!-- rooms -->
              <div class="input-group">
                <label for=""> Rooms </label>
                <a-input-number
                  id="inputNumber"
                  v-model:value="searchData.rooms"
                  :min="1"
                  :max="30"
                  :size="'large'"
                  style="width: 100%; font-size: 16px"
                  placeholder="Any"
                  class="input"
                />
              </div>
            </div>
          </div>
          <div class="right">
            <button
              class="my-btn wd glow"
              @click.prevent="
                $router.push({
                  name: 'Properties',
                  query: searchData,
                })
              "
            >
              <i class="fa fa-search"></i>
              Search
            </button>
          </div>
        </form>
      </div>

      <div class="bg"></div>
    </div>
    <div class="container">
      <div class="filtered-area">
        <div class="category-list">
          <div class="tab-header">
            <h2 class="my-header">Featured Properties</h2>
            <div class="right">
              <label
                for=""
                :class="{ active: currentType === 'all' }"
                @click="
                  currentType = 'all';
                  getFeaturedProps();
                "
                >All</label
              >
              <label
                for=""
                :class="{ active: currentType === 'Sale' }"
                @click="
                  currentType = 'Sale';
                  $router.push({
                    name: 'Properties',
                    query: { type: 'Sale' },
                  });
                "
                >sale</label
              >
              <label
                for=""
                :class="{ active: currentType === 'Rent' }"
                @click="
                  currentType = 'Rent';
                  $router.push({
                    name: 'Properties',
                    query: { type: 'Rent' },
                  });
                "
                >Rent</label
              >
            </div>
          </div>
        </div>
        <div class="items-listing" v-if="homes.length">
          <PropertyCard v-for="item in homes" :key="item.id" :item="item" />
        </div>
        <!-- view more -->
        <div class="view-more d-flex-center mt-10">
          <router-link :to="{ name: 'Properties' }" class="bp cw btn">
            View More
            <i class="fa fa-arrow-right"></i>
          </router-link>
        </div>
      </div>
    </div>
    <div class="how-it-works">
      <div class="head-section">
        <h2 class="header my-header">How It Works</h2>
        <p class="sub-header">
          Discover the perfect property with K-Realty Rwanda in three easy steps
        </p>
      </div>
      <div class="work-items">
        <div class="work-item">
          <router-link :to="{ name: 'Properties' }" class="content">
            <div class="icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="42"
                height="42"
                viewBox="0 0 42 42"
                fill="none"
              >
                <path
                  d="M39.6332 32.9763L32.8763 26.2193L27.6789 24.072C29.4095 21.6111 30.3366 18.6751 30.3333 15.6666C30.3333 7.5794 23.7539 1 15.6666 1C7.5794 1 1 7.5794 1 15.6666C1 23.7539 7.5794 30.3333 15.6666 30.3333C18.7013 30.3367 21.6615 29.3936 24.1349 27.6352L26.2765 32.8185L33.0333 39.5758C33.4666 40.0092 33.981 40.353 34.5472 40.5875C35.1134 40.822 35.7203 40.9428 36.3331 40.9428C36.9459 40.9428 37.5528 40.8221 38.119 40.5876C38.6852 40.3531 39.1997 40.0094 39.633 39.576C40.0664 39.1427 40.4102 38.6283 40.6447 38.0621C40.8792 37.4959 41 36.889 41 36.2762C41 35.6633 40.8793 35.0565 40.6448 34.4903C40.4103 33.9241 40.0666 33.4096 39.6332 32.9763ZM3.66666 15.6666C3.66666 9.04998 9.04998 3.66666 15.6666 3.66666C22.2833 3.66666 27.6666 9.04998 27.6666 15.6666C27.6666 22.2833 22.2833 27.6666 15.6666 27.6666C9.04998 27.6666 3.66666 22.2833 3.66666 15.6666ZM37.7475 37.6902C37.3721 38.0648 36.8635 38.2751 36.3333 38.2751C35.803 38.2751 35.2944 38.0648 34.919 37.6902L28.538 31.3093L26.5469 26.4897L31.3666 28.4809L37.7477 34.8618C38.1221 35.2372 38.3324 35.7458 38.3324 36.2761C38.3324 36.8063 38.122 37.3149 37.7475 37.6902Z"
                  fill="white"
                  stroke="white"
                  stroke-width="0.6"
                />
              </svg>
            </div>
            <div class="text">
              <h4>Search</h4>
              <p>Browse our diverse property listings in Rwanda.</p>
            </div>
          </router-link>
        </div>
        <div class="work-item">
          <router-link :to="{ name: 'Contact' }" class="content">
            <div class="icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="46"
                height="46"
                viewBox="0 0 46 46"
                fill="none"
              >
                <path
                  d="M37.375 41.6875H37.1306C8.88377 40.0631 4.87315 16.2294 4.31252 8.95563C4.26735 8.39008 4.33421 7.82119 4.50928 7.28153C4.68435 6.74187 4.96418 6.24206 5.33274 5.81074C5.7013 5.37941 6.15135 5.02505 6.6571 4.76796C7.16285 4.51086 7.71435 4.35609 8.28002 4.3125H16.2006C16.7764 4.31194 17.3391 4.4843 17.8159 4.80723C18.2926 5.13017 18.6613 5.58881 18.8744 6.12375L21.0594 11.5C21.2698 12.0226 21.322 12.5955 21.2095 13.1475C21.0971 13.6995 20.8249 14.2063 20.4269 14.605L17.365 17.6956C17.8433 20.4136 19.1449 22.919 21.0937 24.873C23.0426 26.827 25.5446 28.1352 28.2613 28.6206L31.3806 25.53C31.7853 25.1364 32.2969 24.8705 32.8516 24.7657C33.4063 24.6609 33.9796 24.7218 34.5 24.9406L39.9194 27.1113C40.4463 27.331 40.8958 27.7027 41.2107 28.1788C41.5256 28.655 41.6916 29.2141 41.6875 29.785V37.375C41.6875 38.5187 41.2332 39.6156 40.4244 40.4244C39.6157 41.2332 38.5188 41.6875 37.375 41.6875ZM8.62502 7.1875C8.24377 7.1875 7.87814 7.33895 7.60855 7.60854C7.33897 7.87812 7.18752 8.24375 7.18752 8.625V8.74C7.84877 17.25 12.0894 37.375 37.2888 38.8125C37.4776 38.8242 37.6669 38.7984 37.8458 38.7368C38.0247 38.6752 38.1896 38.5788 38.3312 38.4533C38.4728 38.3277 38.5882 38.1755 38.6708 38.0052C38.7534 37.835 38.8015 37.6501 38.8125 37.4613V29.785L33.3931 27.6144L29.2675 31.7112L28.5775 31.625C16.0713 30.0581 14.375 17.5519 14.375 17.4225L14.2888 16.7325L18.3713 12.6069L16.215 7.1875H8.62502Z"
                  fill="white"
                />
              </svg>
            </div>
            <div class="text">
              <h4>Connect</h4>
              <p>
                Speak with our team of expert agents for personalized guidance.
              </p>
            </div>
          </router-link>
        </div>
        <router-link
          :to="{ name: 'Properties', query: { status: 'Rent' } }"
          class="work-item"
        >
          <div class="content">
            <div class="icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="55"
                height="57"
                viewBox="0 0 55 57"
                fill="none"
              >
                <path
                  d="M46.4751 23.105L29.4847 6.29894C29.4764 6.29135 29.4686 6.28316 29.4613 6.27445C28.8358 5.67803 28.0206 5.34753 27.175 5.34753C26.3295 5.34753 25.5143 5.67803 24.8888 6.27445L24.8654 6.29894L7.89415 23.105C7.54796 23.4388 7.27163 23.8442 7.08262 24.2957C6.89362 24.7472 6.79607 25.2349 6.79614 25.7279V46.3125C6.79614 47.2573 7.15415 48.1635 7.79142 48.8316C8.42868 49.4997 9.29299 49.875 10.1942 49.875H20.3885C21.2897 49.875 22.154 49.4997 22.7913 48.8316C23.4285 48.1635 23.7865 47.2573 23.7865 46.3125V35.625H30.5827V46.3125C30.5827 47.2573 30.9407 48.1635 31.578 48.8316C32.2152 49.4997 33.0795 49.875 33.9808 49.875H44.175C45.0762 49.875 45.9405 49.4997 46.5778 48.8316C47.2151 48.1635 47.5731 47.2573 47.5731 46.3125V25.7279C47.5731 25.2349 47.4756 24.7472 47.2866 24.2957C47.0976 23.8442 46.8212 23.4388 46.4751 23.105ZM44.175 46.3125H33.9808V35.625C33.9808 34.6802 33.6227 33.774 32.9855 33.1059C32.3482 32.4378 31.4839 32.0625 30.5827 32.0625H23.7865C22.8853 32.0625 22.021 32.4378 21.3837 33.1059C20.7465 33.774 20.3885 34.6802 20.3885 35.625V46.3125H10.1942V25.7279L10.2176 25.7057L27.1846 8.90625L44.1538 25.7012L44.1771 25.7235L44.175 46.3125Z"
                  fill="white"
                />
              </svg>
            </div>
            <div class="text">
              <h4>Rent a House</h4>
              <p>
                Finalize your choise and swiftly complete necessary paperwork.
              </p>
            </div>
          </div>
        </router-link>
      </div>
    </div>
    <div class="container">
      <!-- Browse by Destination  -->
      <div class="location-area">
        <div class="title">
          <h2>Browse properties by location</h2>
          <div class="right">
            <router-link :to="{ name: 'Locations' }">View More</router-link>
          </div>
        </div>
        <ul>
          <li v-for="location in fewlocations" :key="location.location_id">
            <router-link
              :to="{
                name: 'Properties',
                query: { location: location.location_id },
              }"
              class="img"
            >
              <img :src="$upload(location.image)" :alt="location.name" />
            </router-link>
            <router-link
              :to="{
                name: 'Properties',
                query: { location: location.location_id },
              }"
              class="details"
            >
              <p>
                {{
                  $num(
                    location.housesCount +
                      location.apartmentsCount +
                      location.landCount
                  )
                }}
              </p>
              <h3>
                <i class="fa fa-location-dot"></i>
                {{ location.name }}
              </h3>
            </router-link>
          </li>
        </ul>
      </div>
    </div>
    <div class="container">
      <div class="contact-section">
        <div class="contact-body">
          <div class="left">
            <img src="/img/hand.png" alt="" />
            <img src="/img/ell.png" alt="" class="ellipse" />
          </div>
          <div class="right">
            <div class="inner-left">
              <h2>Contact Us for Renting or Selling Your Property!</h2>
              <p>
                Landlords with properties for rent or sale: Register now to
                connect with the K-Realty team!
              </p>
            </div>
            <div class="inner-right">
              <router-link :to="{ name: 'Contact' }" class="my-btn bw wd"
                >Contact Us</router-link
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import { toast } from "vue3-toastify";
export default {
  name: "HomeView",
  components: {},
  data() {
    return {
      statuses: ["For Sale", "For Rent"],
      allCategories: [
        {
          id: 1,
          name: "Hotel",
          icon: "hotel.png",
          count: 28,
        },
        {
          id: 2,
          name: "Motel",
          icon: "motel.png",
          count: 100,
        },
        // apartments, land, commercial, lodge, resort, guest house
        {
          id: 3,
          name: "Apartment",
          icon: "apartment.png",
          count: 100,
        },
        {
          id: 5,
          name: "Lodge",
          icon: "lodge.png",
          count: 100,
        },
        {
          id: 6,
          name: "Resort",
          icon: "resort.png",
          count: 100,
        },
        {
          id: 7,
          name: "Guest House",
          icon: "guest_house.png",
          count: 100,
        },
      ],
      allItems: [
        {
          id: 1,
          name: "House for rent",
          location:
            "Kn82 St /1,Near Kigali City Tower,Nyarugenge Sector,Kiyovu, Kigali, Rwanda",
          category: "Hotel",
          price: 110,
          currency: "USD",
          image: "bg1.png",
          owner: "Cumbinn",
          description:
            "The 2000 Hotel, Kigali's highest hotel, offers mountain and city views as well as a rooftop deck. There is free WiFi throughout the hotel, as well as free private parking. A fitness center is also available. There is a flat-screen TV with satellite channels in each room. Certain rooms have a lounge space where you may unwind after a long day. Enjoy a cup of coffee or tea while looking out at the mountains or garden. Each room has a private bathroom with a bath. Bathrobes, slippers, and free toiletries are available as extras. Bamboo Restaurant serves both Chinese and Western fare. In the bamboo bar, guests can chose from a broad selection of wines. The 2000 Hotel Kigali has an on-site fitness center, as well as massage services. For the convenience of guests, a business center is available. The 24-hour reception is available to assist guests with any questions they may have in English, French, or Chinese. On the ground floor, there is a shopping mall and a grocery. This hotel is a 2-minute walk from the Central Business District and Kigali City Tower. A 5-minute stroll will take you to the Tourist Information Center. The Genocide Memorial is 3 kilometers distant, while the Kigali Convention Center is 7 kilometers away. The 2000 Hotel Downtown Kigali is 11 kilometers from Kigali International Airport. The hotel provides airport transfer service.",
          schedule: "Check-in from 14:00-23:59  - Check-out from 11:00-12:00",
          rooms: [
            {
              id: 1,
              name: "Superior Twin Room",
              price: 130,
              currency: "USD",
              description: "Superior Twin Room",
            },
            {
              id: 2,
              name: "Deluxe King Room Single",
              price: 110,
              currency: "USD",
              description: "Deluxe King Room Single",
            },
            {
              id: 3,
              name: "Executive Suite",
              price: 153,
              currency: "USD",
              description: "Executive Suite",
            },
            {
              id: 4,
              name: "Superior Twin Suite",
              price: 161,
              currency: "USD",
              description: "Superior Twin Suite",
            },
            {
              id: 5,
              name: "Deluxe King Room Double",
              price: 132,
              currency: "USD",
              description: "Deluxe King Room Double",
            },
          ],
          features: [
            "Free Wi-Fi in rooms",
            "Free parking",
            "24-hour reception",
            "Non-smoking rooms",
            "Business center",
            "Fitness center",
            "Spa and wellness center",
            "Bar/ Lounge area",
            "Restaurant",
            "Shuttle service",
            "Laundry",
            "Dry cleaning",
            "Ironing service",
            "Shops/Commercial services",
            "Housekeeping",
            "Car rental",
            "Grocery shopping service",
          ],
          rules: [
            "Pets are not allowed",
            "For your safety to be their top priority, this facility has implemented additional health and hygiene precautions.",
            "Considering your dates are far off, go with free cancellation to maintain your flexibility. With free cancellation, changing your mind about your plans is never a problem!",
            "This establishment has made major efforts to make your stay more environmentally friendly.",
          ],
        },
        {
          id: 2,
          name: "House for rent",
          location:
            "Kn82 St /1,Near Kigali City Tower,Nyarugenge Sector,Kiyovu, Kigali, Rwanda",
          category: "Hotel",
          price: 110,
          currency: "USD",
          image: "bg1.png",
          owner: "Cumbinn",
          description:
            "The 2000 Hotel, Kigali's highest hotel, offers mountain and city views as well as a rooftop deck. There is free WiFi throughout the hotel, as well as free private parking. A fitness center is also available. There is a flat-screen TV with satellite channels in each room. Certain rooms have a lounge space where you may unwind after a long day. Enjoy a cup of coffee or tea while looking out at the mountains or garden. Each room has a private bathroom with a bath. Bathrobes, slippers, and free toiletries are available as extras. Bamboo Restaurant serves both Chinese and Western fare. In the bamboo bar, guests can chose from a broad selection of wines. The 2000 Hotel Kigali has an on-site fitness center, as well as massage services. For the convenience of guests, a business center is available. The 24-hour reception is available to assist guests with any questions they may have in English, French, or Chinese. On the ground floor, there is a shopping mall and a grocery. This hotel is a 2-minute walk from the Central Business District and Kigali City Tower. A 5-minute stroll will take you to the Tourist Information Center. The Genocide Memorial is 3 kilometers distant, while the Kigali Convention Center is 7 kilometers away. The 2000 Hotel Downtown Kigali is 11 kilometers from Kigali International Airport. The hotel provides airport transfer service.",
          schedule: "Check-in from 14:00-23:59  - Check-out from 11:00-12:00",
          rooms: [
            {
              id: 1,
              name: "Superior Twin Room",
              price: 130,
              currency: "USD",
              description: "Superior Twin Room",
            },
            {
              id: 2,
              name: "Deluxe King Room Single",
              price: 110,
              currency: "USD",
              description: "Deluxe King Room Single",
            },
            {
              id: 3,
              name: "Executive Suite",
              price: 153,
              currency: "USD",
              description: "Executive Suite",
            },
            {
              id: 4,
              name: "Superior Twin Suite",
              price: 161,
              currency: "USD",
              description: "Superior Twin Suite",
            },
            {
              id: 5,
              name: "Deluxe King Room Double",
              price: 132,
              currency: "USD",
              description: "Deluxe King Room Double",
            },
          ],
          features: [
            "Free Wi-Fi in rooms",
            "Free parking",
            "24-hour reception",
            "Non-smoking rooms",
            "Business center",
            "Fitness center",
            "Spa and wellness center",
            "Bar/ Lounge area",
            "Restaurant",
            "Shuttle service",
            "Laundry",
            "Dry cleaning",
            "Ironing service",
            "Shops/Commercial services",
            "Housekeeping",
            "Car rental",
            "Grocery shopping service",
          ],
          rules: [
            "Pets are not allowed",
            "For your safety to be their top priority, this facility has implemented additional health and hygiene precautions.",
            "Considering your dates are far off, go with free cancellation to maintain your flexibility. With free cancellation, changing your mind about your plans is never a problem!",
            "This establishment has made major efforts to make your stay more environmentally friendly.",
          ],
        },
        {
          id: 3,
          name: "House for rent",
          location:
            "Kn82 St /1,Near Kigali City Tower,Nyarugenge Sector,Kiyovu, Kigali, Rwanda",
          category: "Hotel",
          price: 110,
          currency: "USD",
          image: "bg1.png",
          owner: "Cumbinn",
          description:
            "The 2000 Hotel, Kigali's highest hotel, offers mountain and city views as well as a rooftop deck. There is free WiFi throughout the hotel, as well as free private parking. A fitness center is also available. There is a flat-screen TV with satellite channels in each room. Certain rooms have a lounge space where you may unwind after a long day. Enjoy a cup of coffee or tea while looking out at the mountains or garden. Each room has a private bathroom with a bath. Bathrobes, slippers, and free toiletries are available as extras. Bamboo Restaurant serves both Chinese and Western fare. In the bamboo bar, guests can chose from a broad selection of wines. The 2000 Hotel Kigali has an on-site fitness center, as well as massage services. For the convenience of guests, a business center is available. The 24-hour reception is available to assist guests with any questions they may have in English, French, or Chinese. On the ground floor, there is a shopping mall and a grocery. This hotel is a 2-minute walk from the Central Business District and Kigali City Tower. A 5-minute stroll will take you to the Tourist Information Center. The Genocide Memorial is 3 kilometers distant, while the Kigali Convention Center is 7 kilometers away. The 2000 Hotel Downtown Kigali is 11 kilometers from Kigali International Airport. The hotel provides airport transfer service.",
          schedule: "Check-in from 14:00-23:59  - Check-out from 11:00-12:00",
          rooms: [
            {
              id: 1,
              name: "Superior Twin Room",
              price: 130,
              currency: "USD",
              description: "Superior Twin Room",
            },
            {
              id: 2,
              name: "Deluxe King Room Single",
              price: 110,
              currency: "USD",
              description: "Deluxe King Room Single",
            },
            {
              id: 3,
              name: "Executive Suite",
              price: 153,
              currency: "USD",
              description: "Executive Suite",
            },
            {
              id: 4,
              name: "Superior Twin Suite",
              price: 161,
              currency: "USD",
              description: "Superior Twin Suite",
            },
            {
              id: 5,
              name: "Deluxe King Room Double",
              price: 132,
              currency: "USD",
              description: "Deluxe King Room Double",
            },
          ],
          features: [
            "Free Wi-Fi in rooms",
            "Free parking",
            "24-hour reception",
            "Non-smoking rooms",
            "Business center",
            "Fitness center",
            "Spa and wellness center",
            "Bar/ Lounge area",
            "Restaurant",
            "Shuttle service",
            "Laundry",
            "Dry cleaning",
            "Ironing service",
            "Shops/Commercial services",
            "Housekeeping",
            "Car rental",
            "Grocery shopping service",
          ],
          rules: [
            "Pets are not allowed",
            "For your safety to be their top priority, this facility has implemented additional health and hygiene precautions.",
            "Considering your dates are far off, go with free cancellation to maintain your flexibility. With free cancellation, changing your mind about your plans is never a problem!",
            "This establishment has made major efforts to make your stay more environmentally friendly.",
          ],
        },
        {
          id: 4,
          name: "House for rent",
          location:
            "Kn82 St /1,Near Kigali City Tower,Nyarugenge Sector,Kiyovu, Kigali, Rwanda",
          category: "Hotel",
          price: 110,
          currency: "USD",
          image: "bg1.png",
          owner: "Cumbinn",
          description:
            "The 2000 Hotel, Kigali's highest hotel, offers mountain and city views as well as a rooftop deck. There is free WiFi throughout the hotel, as well as free private parking. A fitness center is also available. There is a flat-screen TV with satellite channels in each room. Certain rooms have a lounge space where you may unwind after a long day. Enjoy a cup of coffee or tea while looking out at the mountains or garden. Each room has a private bathroom with a bath. Bathrobes, slippers, and free toiletries are available as extras. Bamboo Restaurant serves both Chinese and Western fare. In the bamboo bar, guests can chose from a broad selection of wines. The 2000 Hotel Kigali has an on-site fitness center, as well as massage services. For the convenience of guests, a business center is available. The 24-hour reception is available to assist guests with any questions they may have in English, French, or Chinese. On the ground floor, there is a shopping mall and a grocery. This hotel is a 2-minute walk from the Central Business District and Kigali City Tower. A 5-minute stroll will take you to the Tourist Information Center. The Genocide Memorial is 3 kilometers distant, while the Kigali Convention Center is 7 kilometers away. The 2000 Hotel Downtown Kigali is 11 kilometers from Kigali International Airport. The hotel provides airport transfer service.",
          schedule: "Check-in from 14:00-23:59  - Check-out from 11:00-12:00",
          rooms: [
            {
              id: 1,
              name: "Superior Twin Room",
              price: 130,
              currency: "USD",
              description: "Superior Twin Room",
            },
            {
              id: 2,
              name: "Deluxe King Room Single",
              price: 110,
              currency: "USD",
              description: "Deluxe King Room Single",
            },
            {
              id: 3,
              name: "Executive Suite",
              price: 153,
              currency: "USD",
              description: "Executive Suite",
            },
            {
              id: 4,
              name: "Superior Twin Suite",
              price: 161,
              currency: "USD",
              description: "Superior Twin Suite",
            },
            {
              id: 5,
              name: "Deluxe King Room Double",
              price: 132,
              currency: "USD",
              description: "Deluxe King Room Double",
            },
          ],
          features: [
            "Free Wi-Fi in rooms",
            "Free parking",
            "24-hour reception",
            "Non-smoking rooms",
            "Business center",
            "Fitness center",
            "Spa and wellness center",
            "Bar/ Lounge area",
            "Restaurant",
            "Shuttle service",
            "Laundry",
            "Dry cleaning",
            "Ironing service",
            "Shops/Commercial services",
            "Housekeeping",
            "Car rental",
            "Grocery shopping service",
          ],
          rules: [
            "Pets are not allowed",
            "For your safety to be their top priority, this facility has implemented additional health and hygiene precautions.",
            "Considering your dates are far off, go with free cancellation to maintain your flexibility. With free cancellation, changing your mind about your plans is never a problem!",
            "This establishment has made major efforts to make your stay more environmentally friendly.",
          ],
        },
      ],
      filteredItems: [],
      selectedCategory: "all",
      locations: [],
      fewlocations: [],
      posts: [
        {
          id: 1,
          title: "Top 5 Reasons to Visit Rwanda",
          date: "2023-03-07",
          image: "kigali.png",
          owner: "Cumbinn",
          description:
            "<p>As supporters of Visit Rwanda Campain, we decided to highlight Rwanda on Cumbinn.com. When the lockdown is finally lifted, we have a list of sites on our to-visit list. Like One & Only's Gorilla's Nest, a luxurious eco-resort that debuted in November 2019 and has been winning over Rwanda's wildlife. Eucalyptus trees conceal freestanding lodges, and the One&Only spa uses traditional tribal medicines. A once-in-a-lifetime close experience with Rwanda's gorgeous mountain gorillas wandering freely in their natural habitat, however, is the main draw for tourists.</p><h2>Beautiful, environmentally friendly lodging</h2><p>Singita Kwitonda Lodge, which is perched on the edge of Volcanoes National Park, pushes the bar even higher for sustainable luxury ecotourism. The farm-to-table culinary journey at Kwitonda honors Rwandan cuisine and seasonal ingredients from the property's garden. At the same time, the Akarabo Nursery is an essential component of Singita's ambitious project to help increase the habitat of endangered mountain gorillas through reforestation.</p>",
        },
        {
          id: 2,
          title: "Top 5 Reasons to Visit Rwanda",
          date: "2023-03-07",
          image: "kigali.png",
          owner: "Cumbinn",
          description:
            "<p>As supporters of Visit Rwanda Campain, we decided to highlight Rwanda on Cumbinn.com. When the lockdown is finally lifted, we have a list of sites on our to-visit list. Like One & Only's Gorilla's Nest, a luxurious eco-resort that debuted in November 2019 and has been winning over Rwanda's wildlife. Eucalyptus trees conceal freestanding lodges, and the One&Only spa uses traditional tribal medicines. A once-in-a-lifetime close experience with Rwanda's gorgeous mountain gorillas wandering freely in their natural habitat, however, is the main draw for tourists.</p><h2>Beautiful, environmentally friendly lodging</h2><p>Singita Kwitonda Lodge, which is perched on the edge of Volcanoes National Park, pushes the bar even higher for sustainable luxury ecotourism. The farm-to-table culinary journey at Kwitonda honors Rwandan cuisine and seasonal ingredients from the property's garden. At the same time, the Akarabo Nursery is an essential component of Singita's ambitious project to help increase the habitat of endangered mountain gorillas through reforestation.</p>",
        },
        {
          id: 3,
          title: "Top 5 Reasons to Visit Rwanda",
          date: "2023-03-07",
          image: "kigali.png",
          owner: "Cumbinn",
          description:
            "<p>As supporters of Visit Rwanda Campain, we decided to highlight Rwanda on Cumbinn.com. When the lockdown is finally lifted, we have a list of sites on our to-visit list. Like One & Only's Gorilla's Nest, a luxurious eco-resort that debuted in November 2019 and has been winning over Rwanda's wildlife. Eucalyptus trees conceal freestanding lodges, and the One&Only spa uses traditional tribal medicines. A once-in-a-lifetime close experience with Rwanda's gorgeous mountain gorillas wandering freely in their natural habitat, however, is the main draw for tourists.</p><h2>Beautiful, environmentally friendly lodging</h2><p>Singita Kwitonda Lodge, which is perched on the edge of Volcanoes National Park, pushes the bar even higher for sustainable luxury ecotourism. The farm-to-table culinary journey at Kwitonda honors Rwandan cuisine and seasonal ingredients from the property's garden. At the same time, the Akarabo Nursery is an essential component of Singita's ambitious project to help increase the habitat of endangered mountain gorillas through reforestation.</p>",
        },
        {
          id: 4,
          title: "Top 5 Reasons to Visit Rwanda",
          date: "2023-03-07",
          image: "kigali.png",
          owner: "Cumbinn",
          description:
            "<p>As supporters of Visit Rwanda Campain, we decided to highlight Rwanda on Cumbinn.com. When the lockdown is finally lifted, we have a list of sites on our to-visit list. Like One & Only's Gorilla's Nest, a luxurious eco-resort that debuted in November 2019 and has been winning over Rwanda's wildlife. Eucalyptus trees conceal freestanding lodges, and the One&Only spa uses traditional tribal medicines. A once-in-a-lifetime close experience with Rwanda's gorgeous mountain gorillas wandering freely in their natural habitat, however, is the main draw for tourists.</p><h2>Beautiful, environmentally friendly lodging</h2><p>Singita Kwitonda Lodge, which is perched on the edge of Volcanoes National Park, pushes the bar even higher for sustainable luxury ecotourism. The farm-to-table culinary journey at Kwitonda honors Rwandan cuisine and seasonal ingredients from the property's garden. At the same time, the Akarabo Nursery is an essential component of Singita's ambitious project to help increase the habitat of endangered mountain gorillas through reforestation.</p>",
        },
      ],
      slides: [],
      currentIndex: 0,
      carousel: null,
      currentType: "all",
      homes: [],
      searchData: {
        category: "",
        status: "",
        location: "",
        type: "",
      },
      typeOptions: [
        { value: "", label: "All" },
        { value: "Sale", label: "For Sale" },
        { value: "Rent", label: "For Rent" },
      ],
      statusOptions: [
        { value: "", label: "All" },
        { value: "Furnished", label: "Furnished" },
        { value: "Unfurnished", label: "Unfurnished" },
      ],
      categoryOptions: [
        { value: "", label: "All" },
        { value: "House", label: "House" },
        { value: "Apartment", label: "Apartment" },
        { value: "Land", label: "Land" },
      ],
      locationOptions: [],
    };
  },
  methods: {
    getFeaturedProps() {
      this.$startLoader();
      this.$store
        .dispatch("GET_FEATURED_PROPERTIES", {
          token: this.$getAdminToken(),
          data: {
            paginate: 8,
            page: 1,
          },
        })
        .then((res) => {
          if (res) {
            if (res.status === 200) {
              if (res.data.properties) {
                this.homes = res.data.properties.data;
              }
              this.$stopLoader();
            } else {
              toast.error(res.data.message);
              this.$stopLoader();
            }
          }
        })
        .catch(() => {
          toast.error("Something went wrong, please try again later.");
          this.$stopLoader();
        });
    },
    /*
    getRentFeaturedProps() {
      this.$startLoader();
      this.$store
        .dispatch("GET_RENTFEATURED_PROPERTIES", {
          token: this.$getAdminToken(),
          data: {
            paginate: 8,
            page: 1,
          },
        })
        .then((res) => {
          if (res) {
            if (res.status === 200) {
              if (res.data.properties) {
                this.homes = res.data.properties.data;
              }
              this.$stopLoader();
            } else {
              toast.error(res.data.message);
              this.$stopLoader();
            }
          }
        })
        .catch(() => {
          toast.error("Something went wrong, please try again later.");
          this.$stopLoader();
        });
    },
    getSaleFeaturedProps() {
      this.$startLoader();
      this.$store
        .dispatch("GET_SALEFEATURED_PROPERTIES", {
          token: this.$getAdminToken(),
          data: {
            paginate: 8,
            page: 1,
          },
        })
        .then((res) => {
          if (res) {
            if (res.status === 200) {
              if (res.data.properties) {
                this.homes = res.data.properties.data;
              }
              this.$stopLoader();
            } else {
              toast.error(res.data.message);
              this.$stopLoader();
            }
          }
        })
        .catch(() => {
          toast.error("Something went wrong, please try again later.");
          this.$stopLoader();
        });
    },
    */
    getLocations() {
      this.$store
        .dispatch("GET_LOCATIONS", {
          token: this.$getAdminToken(),
          data: {
            paginate: 1000,
            page: this.currentPage,
          },
        })
        .then((res) => {
          if (res) {
            if (res.status === 200) {
              this.locations = res.data.locations.data;
              this.locationOptions = this.locations.map((location) => {
                return {
                  value: location.location_id,
                  label: location.name,
                };
              });
              this.locationOptions.unshift({ value: "", label: "All" });
            } else {
              toast.error(res.data.message);
            }
          }
        })
        .catch(() => {
          toast.error("Something went wrong, please try again later.");
        });
    },
    getFewLocations() {
      this.$store
        .dispatch("GET_LOCATIONS", {
          token: this.$getAdminToken(),
          data: {
            paginate: 7,
            page: this.currentPage,
          },
        })
        .then((res) => {
          if (res) {
            if (res.status === 200) {
              this.fewlocations = res.data.locations.data;
            } else {
              toast.error(res.data.message);
            }
          }
        })
        .catch(() => {
          toast.error("Something went wrong, please try again later.");
        });
    },
    getSlides() {
      this.$store.dispatch("GET_SLIDES").then((res) => {
        if (res) {
          if (res.status === 200) {
            this.slides = res.data.slides;
          }
        }
      });
    },
    filterOption(input, option) {
      return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    },
  },
  mounted() {
    this.getFeaturedProps();
    this.getLocations();
    this.getSlides();
    this.getFewLocations();

    this.carousel = this.$refs.carousel;
    this.carousel && this.carousel.goTo(0); // Ensure first slide is selected
    this.carousel &&
      this.carousel.$on("change", (val) => (this.currentIndex = val));
  },
  computed: {
    categoryNames() {
      return this.allCategories.map((category) => {
        return category.name;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
$primary_color: #1d2088;
$secondary_color: #1eb9ee;
.home {
  .first-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 90px;
    position: relative;
    min-height: 80vh;
    padding: 0 10rem;
    @media (max-width: 1500px) {
      padding: 0 4rem;
    }
    @media (max-width: 1200px) {
      padding: 0 3rem;
    }
    @media (max-width: 1000px) {
      padding: 0 2.5rem;
    }
    @media (max-width: 750px) {
      padding: 0 2rem;
    }
    @media (max-width: 500px) {
      padding: 0 1rem;
    }
    .bg {
      border-radius: 0px 0px 500px 500px;
      background: linear-gradient(
        180deg,
        #e8ecfd 59.62%,
        rgba(232, 236, 253, 0) 100%
      );
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: -1;
    }
    .top {
      display: flex;
      position: relative;
      @media (max-width: 1000px) {
        width: 100%;
        flex-direction: column;
      }
      .left {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        width: 60%;
        padding-right: 10%;
        @media (max-width: 1500px) {
          padding-right: 5%;
        }
        @media (max-width: 1200px) {
          padding-right: 0;
        }
        @media (max-width: 1000px) {
          width: 100%;
          padding-right: 0;
          padding-top: 2rem;
        }
        @media (max-width: 750px) {
        }
        @media (max-width: 500px) {
        }
        h1 {
          font-size: 4.2rem;
          margin-bottom: 20px;
          color: #000;
          font-weight: 900;
          @media (max-width: 1500px) {
            font-size: 3.8rem;
          }
          @media (max-width: 1200px) {
            font-size: 3rem;
          }
          @media (max-width: 1000px) {
            font-size: 2rem;
            text-align: center;
          }
          @media (max-width: 750px) {
            font-size: 2.5rem;
          }
          @media (max-width: 500px) {
            font-size: 2rem;
          }
        }
        p {
          font-size: 1.5rem;
          margin-bottom: 20px;
          color: $secondary_color;
          @media (max-width: 1500px) {
            font-size: 1.3rem;
          }
          @media (max-width: 1200px) {
            font-size: 1.2rem;
          }
          @media (max-width: 1000px) {
            font-size: 1.1rem;
            text-align: center;
          }
          @media (max-width: 750px) {
            font-size: 1rem;
          }
          @media (max-width: 500px) {
          }
        }
        button {
          padding: 15px 30px;
          background-color: $primary_color;
          color: #fff;
          border: none;
          border-radius: 5px;
          transition: all 0.3s;
          font-size: 1.2rem;
          font-weight: 500;
          &:hover {
            background-color: darken($color: $primary_color, $amount: 10%);
          }
        }
      }
      .right {
        width: 40%;
        padding: 0;
        border-radius: 20px;
        overflow: hidden;
        @media (max-width: 1500px) {
        }
        @media (max-width: 1200px) {
        }
        @media (max-width: 1000px) {
          width: 100%;
          margin-top: 20px;
        }
        @media (max-width: 750px) {
        }
        @media (max-width: 500px) {
        }
        .v-img__img {
          object-fit: contain !important;
        }
      }
    }

    .bottom {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 50px;
      form {
        display: flex;
        background: #fff;
        flex-wrap: wrap;
        align-items: flex-start;
        justify-content: space-between;
        column-gap: 20px;
        width: 100%;
        padding: 20px;
        border-radius: 10px;
        box-shadow: 0px 0px 20px 0px #e0e0e0;
        .left {
          width: 80%;
          @media (max-width: 1500px) {
          }
          @media (max-width: 1200px) {
            width: 100%;
          }
          @media (max-width: 1000px) {
            margin-bottom: 20px;
          }
          @media (max-width: 750px) {
          }
          @media (max-width: 500px) {
          }
          .form-group {
            display: flex;
            justify-content: space-between;
            width: 100%;
            gap: 20px;
            @media (max-width: 1500px) {
            }
            @media (max-width: 1200px) {
              display: grid;
              grid-template-columns: 1fr 1fr;
            }
            @media (max-width: 1000px) {
            }
            @media (max-width: 750px) {
              display: grid;
              grid-template-columns: 1fr;
            }
            @media (max-width: 500px) {
            }
            .input-group {
              display: flex;
              flex-direction: column;
              row-gap: 0;
              position: relative;
              min-width: 150px;
              width: 100%;
              margin-bottom: 0 !important;
              @media (max-width: 1500px) {
              }
              @media (max-width: 1200px) {
                margin-bottom: 1rem;
              }
              @media (max-width: 1000px) {
              }
              @media (max-width: 750px) {
              }
              @media (max-width: 500px) {
              }
              label {
                font-size: 1rem;
                margin-bottom: 0;
                color: $primary_color;
                font-weight: 700;
                padding-left: 5px;
              }
              select {
                padding: 8px 20px;
                padding-left: 5px;
                border: none;
                border-radius: 0px;
                font-size: 1rem;
                color: #555;
                z-index: 1;
                position: relative;
                // border-bottom: 1px #999 solid;
                &:focus {
                  outline: none;
                }
              }
              i {
                position: absolute;
                bottom: 33px;
                right: 10px;
                color: #666;
                font-size: 0.95rem;
              }
            }
          }

          .input {
            .v-field__overlay {
              background: #fff !important;
            }
          }
        }
        .right {
          @media (max-width: 1500px) {
          }
          @media (max-width: 1200px) {
          }
          @media (max-width: 1000px) {
          }
          @media (max-width: 750px) {
            width: 100%;
          }
          @media (max-width: 500px) {
          }
          .my-btn {
            font-size: 16px;
            font-weight: 500;
            @media (max-width: 1500px) {
            }
            @media (max-width: 1200px) {
              width: 100%;
            }
            @media (max-width: 1000px) {
            }
            @media (max-width: 750px) {
            }
            @media (max-width: 500px) {
            }
            i {
              margin-right: 5px;
            }
          }
        }
      }
    }
  }
  .search-area {
    margin-top: 50px;
    border: 1px solid #e0e0e0;
    padding: 20px;
    border-radius: 5px;
    .search {
      h2 {
        margin-bottom: 20px;
      }
      form {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        justify-content: space-between;
        column-gap: 20px;
        .combo {
          width: 30%;
        }
        button {
          width: 30%;
          padding: 15px 0;
          background-color: $primary_color;
          color: #fff;
          border: none;
          border-radius: 5px;
          transition: all 0.3s;
          font-size: 1.2rem;
          font-weight: 500;
          &:hover {
            background-color: darken($color: $primary_color, $amount: 10%);
          }
        }
      }
    }
  }
  .location-area {
    margin-top: 50px;
    margin-bottom: 50px;
    .title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      h2 {
        margin-bottom: 20px;
      }
      .right {
        button,
        a {
          text-decoration: none;
          border-radius: 5px;
          transition: all 0.3s;
          font-size: 1.35rem;
          font-weight: 500;
          color: $primary_color;
        }
      }
    }
    ul {
      list-style: none;
      display: flex;
      justify-content: space-between;
      gap: 20px;
      flex-wrap: wrap;
      @media (max-width: 1500px) {
      }
      @media (max-width: 1280px) {
        display: grid;
        grid-template-columns: 1fr 1fr;
      }
      @media (max-width: 1000px) {
        display: grid;
        grid-template-columns: 1fr;
      }
      @media (max-width: 750px) {
      }
      @media (max-width: 500px) {
      }
      li {
        border: none;
        border-radius: 20px;
        overflow: hidden;
        height: 300px;
        position: relative;
        cursor: pointer;
        .img {
          width: 100%;
          height: 100%;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
        .details {
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          background: linear-gradient(
            0deg,
            rgba(0, 0, 0, 0.603) 0%,
            rgba(0, 0, 0, 0.699) 50%,
            rgba(0, 0, 0, 0) 100%
          );
          padding: 20px 20px;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: flex-start;
          h3 {
            font-size: 1.25rem;
            color: #fff;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            width: 100%;
            font-weight: 300;
            i {
              margin-right: 5px;
              font-size: 1rem;
              width: auto;
            }
          }
          p {
            font-size: 4rem;
            color: #ffffffa8;
            font-weight: 600;
          }
        }
      }
      :nth-child(1),
      :nth-child(2),
      :nth-child(3),
      :nth-child(4) {
        width: 23.5%;
        @media (max-width: 1500px) {
        }
        @media (max-width: 1280px) {
          width: 100%;
        }
        @media (max-width: 1000px) {
        }
        @media (max-width: 750px) {
        }
        @media (max-width: 500px) {
        }
      }
      :nth-child(5) {
        width: 23.5%;
        @media (max-width: 1500px) {
        }
        @media (max-width: 1280px) {
          width: 100%;
        }
        @media (max-width: 1000px) {
        }
        @media (max-width: 750px) {
        }
        @media (max-width: 500px) {
        }
      }
      :nth-child(6) {
        width: 49%;
        @media (max-width: 1500px) {
        }
        @media (max-width: 1280px) {
          width: 100%;
        }
        @media (max-width: 1000px) {
        }
        @media (max-width: 750px) {
        }
      }
      :nth-child(7) {
        width: 23.5%;
        @media (max-width: 1500px) {
        }
        @media (max-width: 1280px) {
          width: 100%;
        }
        @media (max-width: 1000px) {
        }
        @media (max-width: 750px) {
        }
        @media (max-width: 500px) {
        }
      }
    }
    .view-more {
      margin-top: 20px;
      text-align: right;
      a {
        padding: 10px 20px;
        text-decoration: none;
        border-radius: 5px;
        transition: all 0.3s;
        font-size: 1rem;
        font-weight: 500;
      }
    }
  }

  .how-it-works {
    margin-top: 50px;
    background: #fafbff;
    padding: 2rem 0;
    .head-section {
      text-align: center;
      .header {
        margin-bottom: 20px;
      }
      .sub-header {
        font-size: 1.2rem;
        color: $secondary_color;
        @media (max-width: 1500px) {
          font-size: 1.1rem;
        }
        @media (max-width: 1200px) {
          font-size: 1rem;
        }
        @media (max-width: 1000px) {
          font-size: 0.9rem;
        }
        @media (max-width: 750px) {
          font-size: 0.8rem;
        }
        @media (max-width: 500px) {
          font-size: 0.7rem;
        }
      }
    }
    .work-items {
      display: flex;
      justify-content: center;
      margin-top: 50px;
      column-gap: 2rem;
      padding-bottom: 4rem;
      @media (max-width: 1500px) {
      }
      @media (max-width: 1200px) {
      }
      @media (max-width: 1000px) {
      }
      @media (max-width: 750px) {
        flex-direction: column;
      }
      @media (max-width: 500px) {
      }
      .work-item {
        width: 300px;
        border-radius: 20px;
        background: var(--white, #fff);
        box-shadow: 0px 0px 15px 0px #d7dbeb17;
        padding: 4rem 2rem;
        @media (max-width: 1500px) {
        }
        @media (max-width: 1200px) {
        }
        @media (max-width: 1000px) {
        }
        @media (max-width: 750px) {
          width: 100%;
          margin-bottom: 20px;
        }
        @media (max-width: 500px) {
        }
        .content {
          display: flex;
          flex-direction: column;
          align-items: center;
          .icon {
            text-align: center;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 10px;
            background: $primary_color;
            box-shadow: 0px 0px 15px 0px #1d21887e;
            border-radius: 50%;
            height: 80px;
            width: 80px;
            i,
            svg {
              height: 60%;
              width: 60%;
              color: #fff;
            }
          }
          .text {
            h4 {
              font-size: 1.35rem;
              margin-bottom: 10px;
              text-align: center;
              font-weight: 500;
            }
            p {
              font-size: 0.95rem;
              color: #555;
              text-align: center;
            }
          }
        }
      }
    }
  }
}

.contact-section {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fafbff;
  padding: 2rem 0;
  padding-bottom: 5rem;
  .contact-body {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 50px;
    background: $primary_color;
    width: 100%;
    border-radius: 2rem;
    overflow: hidden;
    position: relative;
    @media (max-width: 1500px) {
    }
    @media (max-width: 1200px) {
    }
    @media (max-width: 1000px) {
      margin-top: 0px;
    }
    @media (max-width: 750px) {
    }
    @media (max-width: 500px) {
    }
    .left {
      width: 25%;
      @media (max-width: 1500px) {
      }
      @media (max-width: 1200px) {
      }
      @media (max-width: 1000px) {
        display: none;
      }
      @media (max-width: 750px) {
      }
      @media (max-width: 500px) {
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        z-index: 2;
        position: relative;
      }
      .ellipse {
        position: absolute;
        bottom: 0;
        left: 18%;
        width: 100px;
        height: 100px;
        z-index: 1;
      }
    }
    .right {
      width: 70%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 5rem 2rem;
      column-gap: 2rem;
      @media (max-width: 1500px) {
      }
      @media (max-width: 1200px) {
      }
      @media (max-width: 1000px) {
        width: 100%;
        padding: 2rem;
        flex-direction: column;
      }
      @media (max-width: 750px) {
      }
      @media (max-width: 500px) {
      }
      .inner-left {
        width: 60%;
        @media (max-width: 1500px) {
        }
        @media (max-width: 1200px) {
        }
        @media (max-width: 1000px) {
          width: 100%;
        }
        @media (max-width: 750px) {
        }
        @media (max-width: 500px) {
        }
        h2 {
          font-size: 2rem;
          margin-bottom: 20px;
          color: #fff;
          font-weight: 600;
          @media (max-width: 1500px) {
          }
          @media (max-width: 1200px) {
            font-size: 1.5rem;
          }
          @media (max-width: 1000px) {
            font-size: 1.2rem;
          }
          @media (max-width: 750px) {
            font-size: 1rem;
          }
          @media (max-width: 500px) {
            font-size: 1rem;
          }
        }
        p {
          font-size: 1rem;
          color: #fff;
          @media (max-width: 1500px) {
          }
          @media (max-width: 1200px) {
            font-size: 0.9rem;
          }
          @media (max-width: 1000px) {
            font-size: 0.8rem;
          }
          @media (max-width: 750px) {
            font-size: 0.7rem;
          }
          @media (max-width: 500px) {
            font-size: 0.7rem;
          }
        }
      }
      .inner-right {
        width: 30%;
        @media (max-width: 1500px) {
        }
        @media (max-width: 1200px) {
        }
        @media (max-width: 1000px) {
          width: 100%;
          display: block;
          margin-top: 2rem;
        }
        @media (max-width: 750px) {
        }
        @media (max-width: 500px) {
        }
        button {
          font-weight: 600;
          font-size: 17px;
          @media (max-width: 1500px) {
          }
          @media (max-width: 1200px) {
          }
          @media (max-width: 1000px) {
            display: block;
          }
          @media (max-width: 750px) {
          }
          @media (max-width: 500px) {
          }
        }
      }
    }
  }
}
.v-combo >>> .v-combobox {
  box-shadow: none !important;
  border: 1px solid #e0e0e0;
}
</style>
