<template>
  <div class="dashboard">
    <div class="dash-content">
      <h1 class="page-title">
        <span>LOCATIONS</span>
        <form class="form-search" @submit.prevent="getItems()">
          <input type="text" placeholder="Search" v-model="searchQuery" />
        </form>
        <button class="my-btn cw glow wd" @click="startAdd = true">
          Add Location
        </button>
      </h1>

      <div class="my-table">
        <table>
          <thead>
            <tr>
              <th>Image</th>
              <th>Location</th>
              <th>Address</th>
              <th>Houses</th>
              <th>Apartments</th>
              <th>Plots</th>
              <th>Added by</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in locations" :key="item.id">
              <td>
                <img :src="$upload(item.image)" alt="Location Image" />
              </td>
              <td>{{ item.name }}</td>
              <td>{{ item.address }}</td>
              <td>{{ item.housesCount }}</td>
              <td>{{ item.apartmentsCount }}</td>
              <td>{{ item.landCount }}</td>
              <td>{{ item.created_by.name }}</td>
              <td>
                <button
                  @click="
                    selectedItem = item;
                    startEdit = true;
                  "
                  class="btn edit"
                >
                  Edit
                </button>
                <button
                  @click="
                    selectedItem = item;
                    startDelete = true;
                  "
                  class="btn delete"
                >
                  Delete
                </button>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="pagination">
          <button
            :class="{ disabled: currentPage === 1 }"
            @click="$previousPage()"
          >
            <label>Previous</label>
          </button>
          <p v-if="currentPage > 3">...</p>
          <button class="numbers" v-for="number in pagesNumber" :key="number">
            <label
              :class="{ active: currentPage === number }"
              for=""
              v-if="
                (number == 1 && currentPage <= 2) ||
                (currentPage + 2 >= pagesNumber && number >= pagesNumber - 2) ||
                (number >= currentPage - 2 && number <= currentPage + 2)
              "
              @click="$changePage(number)"
            >
              {{ number }}
            </label>
          </button>
          <p v-if="currentPage <= pagesNumber - 3">...</p>
          <button
            :class="{ disabled: currentPage === pagesNumber.length }"
            @click="$nextPage()"
          >
            <label>Next</label>
          </button>
        </div>
      </div>
      <Model
        v-if="startAdd"
        @closeModel="startAdd = false"
        :modelTitle="'Add Location'"
        :size="'sm'"
      >
        <div class="model-body">
          <div class="form-group">
            <label for="name">Location Name</label>
            <input
              type="text"
              id="name"
              v-model="newItem.name"
              class="form-control"
            />
          </div>
          <div class="form-group">
            <label for="address">Location Address</label>
            <input
              type="text"
              id="address"
              v-model="newItem.address"
              class="form-control"
            />
          </div>
          <div class="form-group">
            <label for="address">Order</label>
            <input
              type="text"
              id="address"
              v-model="newItem.order"
              class="form-control"
            />
          </div>
          <div class="form-group">
            <label for="image">Location Image</label>
            <o-upload
              :multiple="false"
              @imagesUploaded="
                (data) => {
                  newItem.image = data;
                }
              "
              @imagesUploadedFailed="
                (data) => {
                  console.log('failed');
                }
              "
              :value="newItem.image"
              @startLoader="() => $startLoader()"
              @stopLoader="() => $stopLoader()"
            />
          </div>
          <div class="btns flex">
            <button @click="startAdd = false" class="my-btn cw wd cancel">
              Cancel
            </button>
            <button @click="createItem" class="my-btn cw glow wd">
              Add Location
            </button>
          </div>
        </div>
      </Model>
      <Model
        v-if="startEdit"
        @closeModel="startEdit = false"
        :modelTitle="'Edit Location'"
        :size="'sm'"
      >
        <div class="model-body">
          <div class="form-group">
            <label for="name">Location Name</label>
            <input
              type="text"
              id="name"
              v-model="selectedItem.name"
              class="form-control"
            />
          </div>
          <div class="form-group">
            <label for="address">Location Address</label>
            <input
              type="text"
              id="address"
              v-model="selectedItem.address"
              class="form-control"
            />
          </div>
          <div class="form-group">
            <label for="address">Order</label>
            <input
              type="text"
              id="address"
              v-model="selectedItem.order"
              class="form-control"
            />
          </div>
          <div class="form-group">
            <label for="image">Location Image</label>
            <o-upload
              :multiple="false"
              @imagesUploaded="
                (data) => {
                  selectedItem.image = data;
                }
              "
              @imagesUploadedFailed="
                (data) => {
                  console.log('failed');
                }
              "
              :value="selectedItem.image"
              @startLoader="() => $startLoader()"
              @stopLoader="() => $stopLoader()"
            />
          </div>
          <div class="btns flex">
            <button @click="startEdit = false" class="my-btn cw wd cancel">
              Cancel
            </button>
            <button @click="updateItem" class="my-btn cw glow wd">
              Update Location
            </button>
          </div>
        </div>
      </Model>
      <confirmModel
        v-if="startDelete"
        @closeModel="startDelete = false"
        :modelTitle="'Delete Location'"
        :size="'sm'"
        :displayText="'Are you sure you want to delete this location?'"
        @confirmModel="deleteItem"
      />
    </div>
  </div>
</template>

<script>
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
export default {
  data() {
    return {
      locations: [],
      newItem: {
        name: "",
        address: "",
        image: "",
        order: 100,
      },
      selectedItem: null,
      startAdd: false,
      startEdit: false,
      startDelete: false,
      allItemsCount: 100,
      itemsPerpage: 10,
      pagesNumber: 0,
      currentPage: 1,
      searchQuery: "",
    };
  },
  methods: {
    getItems() {
      this.$startLoader();
      this.$store
        .dispatch("GET_LOCATIONS", {
          token: this.$getAdminToken(),
          data: {
            paginate: 10,
            page: this.currentPage,
            searchQuery: this.searchQuery,
          },
        })
        .then((res) => {
          if (res) {
            if (res.status === 200) {
              this.locations = res.data.locations.data;
              this.allItemsCount = res.data.locations.total;
              this.pagesNumber = Math.ceil(
                this.allItemsCount / this.itemsPerpage
              );
              this.$stopLoader();
            } else {
              toast.error(res.data.message);
              this.$stopLoader();
            }
          }
        })
        .catch(() => {
          toast.error("Something went wrong, please try again later.");
          this.$stopLoader();
        });
    },
    createItem() {
      if (this.newItem.name && this.newItem.address) {
        this.$startLoader();
        this.$store
          .dispatch("CREATE_LOCATION", {
            data: this.newItem,
            token: this.$getAdminToken(),
          })
          .then((res) => {
            if (res) {
              if (res.status === 200) {
                toast.success(res.data.message);
                this.getItems();
                this.startAdd = false;
                this.$stopLoader();
              } else {
                toast.error(res.data.message);
                this.$stopLoader();
              }
            }
          })
          .catch(() => {
            toast.error("Something went wrong, please try again later.");
            this.$stopLoader();
          });
      } else {
        toast.error("Please fill all fields");
      }
    },
    updateItem() {
      if (this.selectedItem.name && this.selectedItem.address) {
        this.$startLoader();
        this.$store
          .dispatch("UPDATE_LOCATION", {
            data: this.selectedItem,
            token: this.$getAdminToken(),
          })
          .then((res) => {
            if (res) {
              if (res.status === 200) {
                toast.success(res.data.message);
                this.getItems();
                this.startEdit = false;
                this.$stopLoader();
              } else {
                toast.error(res.data.message);
                this.$stopLoader();
              }
            }
          })
          .catch(() => {
            toast.error("Something went wrong, please try again later.");
            this.$stopLoader();
          });
      } else {
        toast.error("Please fill all fields");
      }
    },
    deleteItem() {
      this.$startLoader();
      this.$store
        .dispatch("DELETE_LOCATION", {
          data: this.selectedItem,
          token: this.$getAdminToken(),
        })
        .then((res) => {
          if (res) {
            if (res.status === 200) {
              toast.success(res.data.message);
              this.getItems();
              this.startDelete = false;
              this.$stopLoader();
            } else {
              toast.error(res.data.message);
              this.$stopLoader();
            }
          }
        })
        .catch(() => {
          toast.error("Something went wrong, please try again later.");
          this.$stopLoader();
        });
    },
  },
  mounted() {
    this.getItems();
  },
};
</script>

<style lang="scss" scoped>
$primary_color: #1d2088;
$secondary_color: #1eb9ee;
.dashboard {
  padding-bottom: 5rem;
  .page-title {
    margin: 20px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    span {
      font-size: 1.5rem;
      font-weight: 600;
    }
    button {
      padding: 0.5rem 1rem;
      background-color: $primary_color;
      color: white;
      border: none;
      border-radius: 5px;
      cursor: pointer;
      transition: all 0.3s ease-in-out;
      font-size: 1rem;
      &:hover {
        background-color: $secondary_color;
      }
    }
  }
  .dash-content {
    padding: 0 2rem;
    .btns {
      margin-top: 1rem;
      display: flex;
      justify-content: space-between;
      button {
        margin-right: 10px;
        padding: 0.75rem 1rem;
      }
    }
  }
}
</style>