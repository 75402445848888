<template>
  <div class="dashboard">
    <div class="dash-content">
      <h1 class="page-title">
        <span>Requests</span>
      </h1>

      <div class="my-table">
        <table>
          <thead>
            <tr>
              <th>#</th>
              <th>Property</th>
              <th>Name</th>
              <th>Phone</th>
              <th>Email</th>
              <th>Address</th>
              <th>Date</th>
              <th>Status</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in requests" :key="item.request_id">
              <td>{{ index + 1 }}</td>
              <td>
                <router-link
                  :to="{
                    name: 'ViewProperty',
                    params: {
                      id: item.property.property_id,
                      name: $urlCompatible(item.property.title),
                    },
                  }"
                  target="_blank"
                >
                  {{ item.property.property_idno }} - {{ item.property.title }}
                </router-link>
              </td>
              <td>{{ item.full_name }}</td>
              <td>
                <a :href="'https://wa.me/' + item.phone" target="_blank">{{
                  item.phone
                }}</a>
              </td>
              <td>{{ item.email }}</td>
              <td>{{ item.address }}</td>
              <td>{{ item.date }} {{ item.time }}</td>
              <td>
                <span :class="item.status">{{ item.status }}</span>
              </td>
              <td>
                <button
                  @click="
                    selectedItem = item;
                    startEdit = true;
                  "
                  class="btn edit"
                >
                  Edit
                </button>
                <button
                  @click="
                    selectedItem = item;
                    startDelete = true;
                  "
                  class="btn delete"
                >
                  Delete
                </button>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="pagination">
          <button
            :class="{ disabled: currentPage === 1 }"
            @click="$previousPage()"
          >
            <label>Previous</label>
          </button>
          <p v-if="currentPage > 3">...</p>
          <button class="numbers" v-for="number in pagesNumber" :key="number">
            <label
              :class="{ active: currentPage === number }"
              for=""
              v-if="
                (number == 1 && currentPage <= 2) ||
                (currentPage + 2 >= pagesNumber && number >= pagesNumber - 2) ||
                (number >= currentPage - 2 && number <= currentPage + 2)
              "
              @click="$changePage(number)"
            >
              {{ number }}
            </label>
          </button>
          <p v-if="currentPage <= pagesNumber - 3">...</p>
          <button
            :class="{ disabled: currentPage === pagesNumber.length }"
            @click="$nextPage()"
          >
            <label>Next</label>
          </button>
        </div>
      </div>
      <Model
        v-if="startEdit"
        @closeModel="startEdit = false"
        :modelTitle="'Edit Request'"
        :size="'sm'"
      >
        <div class="model-body">
          <div class="form-group">
            <label for="name">Client Names</label>
            <input
              type="text"
              id="name"
              v-model="selectedItem.full_name"
              class="form-control"
            />
          </div>
          <div class="form-group">
            <label for="phone">Phone</label>
            <input
              type="text"
              id="phone"
              v-model="selectedItem.phone"
              class="form-control"
            />
          </div>
          <div class="form-group">
            <label for="email">Email</label>
            <input
              type="text"
              id="email"
              v-model="selectedItem.email"
              class="form-control"
            />
          </div>
          <div class="form-group">
            <label for="address">Address</label>
            <input
              type="text"
              id="address"
              v-model="selectedItem.address"
              class="form-control"
            />
          </div>
          <div class="form-group">
            <label for="status">Status</label>
            <select
              id="status"
              v-model="selectedItem.status"
              class="form-control"
            >
              <option value="pending">Pending</option>
              <option value="on-hold">On Hold</option>
              <option value="processing">Processing</option>
              <option value="cancelled">Cancelled</option>
              <option value="unmatched">Unmatched</option>
              <option value="visiting">Visiting</option>
              <option value="negociating">Negociating</option>
              <option value="completed">Completed</option>
            </select>
          </div>
          <div class="btns flex">
            <button @click="startEdit = false" class="my-btn cw wd cancel">
              Cancel
            </button>
            <button @click="updateItem" class="my-btn cw glow wd">
              Update Request
            </button>
          </div>
        </div>
      </Model>
      <confirmModel
        v-if="startDelete"
        @closeModel="startDelete = false"
        :modelTitle="'Delete Request'"
        :size="'sm'"
        :displayText="'Are you sure you want to delete this Request?'"
        @confirmModel="deleteItem"
      />
    </div>
  </div>
</template>

<script>
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
export default {
  data() {
    return {
      requests: [],
      selectedItem: null,
      startAdd: false,
      startEdit: false,
      startDelete: false,
      allItemsCount: 100,
      itemsPerpage: 10,
      pagesNumber: 0,
      currentPage: 1,
    };
  },
  methods: {
    getItems() {
      this.$startLoader();
      this.$store
        .dispatch("GET_REQUESTS", {
          token: this.$getAdminToken(),
          data: {
            paginate: 10,
            page: this.currentPage,
          },
        })
        .then((res) => {
          if (res) {
            if (res.status === 200) {
              this.requests = res.data.requests.data;
              this.allItemsCount = res.data.requests.total;
              this.pagesNumber = Math.ceil(
                this.allItemsCount / this.itemsPerpage
              );
              this.$stopLoader();
            } else {
              toast.error(res.data.message);
              this.$stopLoader();
            }
          }
        })
        .catch(() => {
          toast.error("Something went wrong, please try again later.");
          this.$stopLoader();
        });
    },
    updateItem() {
      this.$startLoader();
      this.$store
        .dispatch("UPDATE_REQUEST", {
          data: this.selectedItem,
          token: this.$getAdminToken(),
        })
        .then((res) => {
          if (res) {
            if (res.status === 200) {
              toast.success(res.data.message);
              this.getItems();
              this.startEdit = false;
              this.$stopLoader();
            } else {
              toast.error(res.data.message);
              this.$stopLoader();
            }
          }
        })
        .catch(() => {
          toast.error("Something went wrong, please try again later.");
          this.$stopLoader();
        });
    },
    deleteItem() {
      this.$startLoader();
      this.$store
        .dispatch("DELETE_REQUEST", {
          data: this.selectedItem,
          token: this.$getAdminToken(),
        })
        .then((res) => {
          if (res) {
            if (res.status === 200) {
              toast.success(res.data.message);
              this.getItems();
              this.startDelete = false;
              this.$stopLoader();
            } else {
              toast.error(res.data.message);
              this.$stopLoader();
            }
          }
        })
        .catch(() => {
          toast.error("Something went wrong, please try again later.");
          this.$stopLoader();
        });
    },
  },
  mounted() {
    this.getItems();
  },
};
</script>

<style lang="scss" scoped>
$primary_color: #1d2088;
$secondary_color: #1eb9ee;
.dashboard {
  padding-bottom: 5rem;
  .page-title {
    margin: 20px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    span {
      font-size: 1.5rem;
      font-weight: 600;
    }
    button {
      padding: 0.5rem 1rem;
      background-color: $primary_color;
      color: white;
      border: none;
      border-radius: 5px;
      cursor: pointer;
      transition: all 0.3s ease-in-out;
      font-size: 1rem;
      &:hover {
        background-color: $secondary_color;
      }
    }
  }
  .dash-content {
    padding: 0 2rem;
    .my-table {
      table {
        width: 100%;
        border-collapse: collapse;
        thead {
          th {
            text-align: left;
            padding: 1rem 0;
            font-weight: 600;
            border-bottom: 1px solid #e5e8ee;
          }
        }
        tbody {
          tr {
            td {
              padding: 5px;
              border-bottom: 1px solid #e5e8ee;
              img {
                width: 100px;
                height: 50px;
                object-fit: cover;
                border-radius: 5px;
              }
              button {
                margin-right: 10px;
                &.edit {
                  background-color: #1d2088;
                  color: white;
                  &:hover {
                    background-color: #1eb9ee;
                  }
                }
                &.delete {
                  background-color: #a82929;
                  color: white;
                  &:hover {
                    background-color: #7a1f1f;
                  }
                }
                &.default {
                  background-color: #1d2088;
                  color: white;
                  &:hover {
                    background-color: #1eb9ee;
                  }
                }
              }
            }
          }
        }
      }
    }
    .btns {
      margin-top: 1rem;
      display: flex;
      justify-content: space-between;
      button {
        margin-right: 10px;
        padding: 0.75rem 1rem;
        &.cancel {
          background-color: #a82929;
          color: white;
          &:hover {
            background-color: #7a1f1f;
          }
        }
      }
    }
  }
}
td {
  span {
    &.pending {
      background-color: #f5a623;
      color: white;
      padding: 0.15rem 0.6rem;
      border-radius: 50px;
      font-weight: 400;
      font-size: 0.8rem;
    }
    &.on-hold {
      background-color: #000;
      color: white;
      padding: 0.15rem 0.6rem;
      border-radius: 50px;
      font-weight: 400;
      font-size: 0.8rem;
    }
    &.processing {
      background-color: #1d2088;
      color: white;
      padding: 0.15rem 0.6rem;
      border-radius: 50px;
      font-weight: 400;
      font-size: 0.8rem;
    }
    &.cancelled {
      background-color: #a82929;
      color: white;
      padding: 0.15rem 0.6rem;
      border-radius: 50px;
      font-weight: 400;
      font-size: 0.8rem;
    }
    &.unmatched {
      background-color: #a82929;
      color: white;
      padding: 0.15rem 0.6rem;
      border-radius: 50px;
      font-weight: 400;
      font-size: 0.8rem;
    }
    &.visiting {
      background-color: #1d2088;
      color: white;
      padding: 0.15rem 0.6rem;
      border-radius: 50px;
      font-weight: 400;
      font-size: 0.8rem;
    }
    &.negociating {
      background-color: #1d2088;
      color: white;
      padding: 0.15rem 0.6rem;
      border-radius: 50px;
      font-weight: 400;
      font-size: 0.8rem;
    }
    &.completed {
      background-color: #1d883d;
      color: white;
      padding: 0.15rem 0.6rem;
      border-radius: 50px;
      font-weight: 400;
      font-size: 0.8rem;
    }
  }
}
</style>