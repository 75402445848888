<script>
export default {
  data() {
    return {
      primary_color: "#1d2088",
      secondary_color: "#1eb9ee",
    };
  },
  methods: {
    $urlCompatible(name, roll = false) {
      if (roll) {
        return name.toLowerCase().replace(/\s/g, "-");
      }
      return name.toLowerCase().replace(/\s/g, "-");
    },
    $img(name) {
      return `/img/${name}`;
    },
    $upload(name) {
      return this.$store.state.apiUploadUrl + `${name}`;
    },
    $startLoader() {
      document.body.style.overflow = "hidden";
      this.$store.state.startLoader = true;
    },
    $stopLoader() {
      document.body.style.overflow = "auto";
      this.$store.state.startLoader = false;
    },
    $urlContains(url) {
      return this.$route.path.includes(url);
    },
    $AdminLogin(user, token) {
      localStorage.setItem("k_admin", JSON.stringify(user));
      localStorage.setItem("k_admin_token", token);
      this.$router.push({ name: "AdminDashboard" });
    },
    $getUser() {
      return JSON.parse(localStorage.getItem("k_admin"));
    },
    $getAdminToken() {
      return localStorage.getItem("k_admin_token");
    },
    $userLogout() {
      localStorage.removeItem("k_admin");
      localStorage.removeItem("k_admin_token");
      this.$router.push({ name: "AdminLogin" });
    },
    $imageurlalt() {
      event.target.src = "https://via.placeholder.com/300";
    },
    $price(price) {
      if (isNaN(price)) {
        return price;
      } else {
        return "$" + price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
    },
    $date(date, type = "date") {
      if (!date) {
        return;
      }
      let newDate = new Date(date);
      let year = newDate.getFullYear();
      let dayName = newDate.toLocaleString("default", { weekday: "long" });
      let dayNumber = newDate.toLocaleString("default", { day: "numeric" });
      // add 0 before day if less than 10
      if (dayNumber < 10) {
        dayNumber = "0" + dayNumber;
      }
      let monthName = newDate.toLocaleString("default", { month: "long" });
      let monthNameShort = newDate.toLocaleString("default", {
        month: "short",
      });
      let monthNumber = newDate.toLocaleString("default", { month: "numeric" });
      // add 0 before month if less than 10
      if (monthNumber < 10) {
        monthNumber = "0" + monthNumber;
      }
      let dateDots = `${dayNumber}.${monthNumber}.${year}`;
      if (type == "date") {
        return `${dayName}, ${dayNumber} ${monthName}, ${year}`;
      }
      if (type == "dots") {
        return dateDots;
      }
      if (type == "day") {
        return dayNumber;
      }
      if (type == "month") {
        return monthNameShort;
      }
      if (type == "year") {
        return year;
      }
    },
    $ago(date) {
      if (!date) {
        return;
      }
      // get time ago since  date
      let newDate = new Date(date);
      let today = new Date();
      let diff = today - newDate;
      let seconds = Math.floor(diff / 1000);
      let minutes = Math.floor(seconds / 60);
      let hours = Math.floor(minutes / 60);
      let days = Math.floor(hours / 24);
      let weeks = Math.floor(days / 7);
      let months = Math.floor(days / 30);
      let years = Math.floor(days / 365);
      if (seconds < 60) {
        return seconds + " seconds ago";
      } else if (minutes < 60) {
        return minutes + " minutes ago";
      } else if (hours < 24) {
        return hours + " hours ago";
      } else if (days < 7) {
        return days + " days ago";
      } else if (weeks < 4) {
        return weeks + " weeks ago";
      } else if (months < 12) {
        return months + " months ago";
      } else {
        return years + " years ago";
      }
    },
    $toggleSANav() {
      this.$store.state.showSANav = !this.$store.state.showSANav;
      localStorage.setItem("showSANav", this.$store.state.showSANav);
    },
    $comma(amount) {
      if (!amount) {
        return;
      }
      return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    $changePage(page) {
      if (this.currentPage === page) return;
      this.currentPage = page;
      this.getItems();
    },
    $previousPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
        this.getItems();
      }
    },
    $nextPage() {
      if (this.currentPage < this.pagesNumber) {
        this.currentPage++;
        this.getItems();
      }
    },
    $num(val) {
      // add 0 before number if less than 10
      if (val < 10) {
        return "0" + val;
      }
      return val;
    },
    $money(amount, currency) {
      let defaultCurrency = localStorage.getItem("kcurrency");
      let rate = this.$store.state.currencies.find(
        (c) => c.name == defaultCurrency
      ).rate;
      // only 2 decimal places
      let convoAmount = (amount * rate).toFixed(2);
      // if the 2 decimal places are 00, remove them
      if (convoAmount.slice(-3) == ".00") {
        return convoAmount.slice(0, -3) + " " + defaultCurrency;
      }
      return convoAmount + " " + defaultCurrency;
    },
  },
};
</script>